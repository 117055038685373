@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@700&display=swap');
/* Left Navigation
		-----------------------------------------------*/
		.mainNav {
			background: transparent;
			display: none;
			@media only screen and (max-width: 1240px) {
				display: inherit;
			}
		}
			/* First Level */
			.mainNav ul {
				margin: 0;
				padding: 0;
				list-style: none;
				border-bottom: 1px solid transparent;
				li.active {
					border-top: 1px solid #ffffff;
					ul {
						border-top: 1px solid #ffffff;
						display: block !important;
					}
				}
			}
			.mainNav ul li {
				border-top: 1px solid transparent;
				position: relative !important;
				&::after {
					content: "";
					clear: both;
					display: table;
				}
				span {
					width: 100% !important;
					position: absolute !important;
				}
			}
			.mainNav ul li a {
				color: #FFFFFF;
				font-size: 32px;
				padding:12px 0;
				text-decoration:none;
				font-family: 'Roboto Slab', serif;
    			margin-right: 0 !important;
				display: block;
			}

			.mainNav ul li {
				a::after {
					display: inline-block;
                    margin-left: 12px;
                    vertical-align: inherit;
                    font-family: "FontAwesome";
                    font-weight: 400;
                    content: "\f0d7";
                    font-size: 23px;
                    line-height: 0;
				}
				ul a::after {
					display: none;
				}
			}

			.mainNav ul li.active {
				a::after {
					display: inline-block;
                    margin-left: 12px;
                    vertical-align: inherit;
                    font-family: "FontAwesome";
                    font-weight: 400;
                    content: "\f0d8";
                    font-size: 23px;
                    line-height: 0;
				}
				ul a::after {
					display: none;
				}
			}

			.mainNav ul li a:hover {
				background: transparent;
				text-decoration: none;
			}
				/* Second Level */
				.mainNav ul ul {
					border-bottom: none;
				}
				.mainNav ul ul li {
					border-top: 1px solid transparent;
					background: transparent;
				}
				.mainNav ul ul li a {
					color: #FFFFFF;
					display: block;
					font-size: 18px;
					line-height: normal;
					padding: 0.5em 1em 0.5em 1em;
					font-family: "Open Sans", sans-serif;
					&:hover {
						text-decoration: underline;
						text-decoration-thickness: 2px;
					}
				}
				.mainNav ul ul li a:hover {
					background: transparent;
				}
						/* Third Level */
				.mainNav ul ul ul {
					border-top:1px solid transparent;
				}
				.mainNav ul ul ul li {
					border:none;
				}
				.mainNav ul ul ul li a {
					padding-left:3.5em; 
					padding-top:0.25em; 
					padding-bottom:0.25em;
				}
			/* Accordion Button */
			ul li.has-subnav .accordion-btn {
				color:#fff; 
				background: transparent;
				font-size:16px;
    			border: none;
				line-height: 68px !important;
    			height: 68px !important;
				.fa {
					font-size: 24px;
					display: none;
				}
			}
		
		@media screen and (max-width: 700px) {
			.mainNav {width: 100%;}
		}